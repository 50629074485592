<template>
  <div class="option-list">
    <div
      v-if="!isUploadingOrPending"
      class="option-list__add-option"
    >
      <StitchTooltip
        :is-disabled="canManageOptions"
        :message="manageOptionsDisabledMessages.ADD"
        placement="top"
      >
        <ElementButton
          ref="addOptionButton"
          :disabled="!canManageOptions"
          type="text"
          @click="emitAddOption"
        >
          Add New Colorway
        </ElementButton>
      </StitchTooltip>
    </div>
    <div
      ref="optionList"
      class="option-list__options"
    >
      <div
        v-if="showError"
        class="render-status"
      >
        <h2>{{ errorMessage.title }}</h2>
        <p>{{ errorMessage.instruction }}</p>
        <p>
          Need help? Email us at
          <a href="mailto:3Dsupport@pvh.com">3Dsupport@pvh.com</a>.
        </p>
      </div>
      <template v-else>
        <div
          v-if="isUploadingOrPending"
          ref="loader"
          class="option-item option-item__option--rendering"
        >
          <OptionRenderSteps :version="version" />
        </div>
        <Draggable
          v-else
          ghost-class="option-list__dragged"
          v-bind="{ animation: 150 }"
          @end="onSortDrop"
          @start="disableDragDropUpload"
        >
          <OptionItem
            v-for="option in options"
            :id="option.id"
            :key="option.id"
            :can-manage-options="canManageOptions"
            :option="option"
            :version="version"
            class="option-list__option"
            @update-option="handleUpdateOption"
            @delete-option="handleDeleteOption"
            @add-images="handleAddImages"
            @update-image="handleUpdateImage"
            @delete-image="handleDeleteImage"
          />
        </Draggable>
      </template>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'

import {
  JOB_STATUS,
  RENDER_JOB_FAILED_MESSAGE
} from '@/constants/loadingStatus'

import VueTypes from 'vue-types'
import { OptionShape, VersionShape } from '@/types'
import OptionItem from './components/OptionItem'
import OptionRenderSteps from './components/OptionRenderSteps'

export default {
  name: 'OptionList',

  components: {
    Draggable,
    OptionItem,
    OptionRenderSteps
  },

  props: {
    activeVersion: VueTypes.objectOf(VersionShape).loose,
    options: VueTypes.arrayOf(OptionShape)
  },

  emits: [
    'add-option',
    'update-option',
    'delete-option',
    'add-option-images',
    'update-option-image',
    'delete-option-image'
  ],

  data () {
    return {
      manageOptionsDisabledMessages: {
        ADD: [
          'This version comes from a .BW file. Add a colorway',
          'on the 3D file and upload it as a new version.'
        ]
      },
      errorMessages: {
        NO_OPTIONS: 'Please Add New Colorway'
      },
      isSorting: false,
      helpCenterLink:
        'https://hub-support.freshdesk.com/support/solutions/articles/101000446535-browzwear-file-fails-to-upload'
    }
  },

  computed: {
    ...mapGetters(['getVersionById']),

    /**
     * @returns {object}
     */
    version () {
      const version = this.getVersionById(this.activeVersion.id)

      if (this.activeVersion.isUploading || !version) {
        return this.activeVersion
      }

      return version
    },

    /**
     * @returns {boolean}
     */
    isVersionEmpty () {
      return this.options.length === 0
    },

    /**
     * @returns {boolean}
     */
    showError () {
      return (
        this.version.autorenderStatus === JOB_STATUS.FAILED ||
        (this.isVersionEmpty && !this.isUploadingOrPending)
      )
    },

    /**
     * @returns {boolean}
     */
    canManageOptions () {
      return (
        this.version.model === null ||
        (this.version.model && !this.version.model.endsWith('.bw'))
      )
    },

    /**
     * @returns {object}
     */
    errorMessage () {
      const renderErrorMessage = this.version.autorenderStatusMessage
      switch (renderErrorMessage) {
        case RENDER_JOB_FAILED_MESSAGE.SNAPSHOT_ERROR.renderFarmMessage:
          return RENDER_JOB_FAILED_MESSAGE.SNAPSHOT_ERROR
        case RENDER_JOB_FAILED_MESSAGE.NO_SNAPSHOTS.renderFarmMessage:
          return RENDER_JOB_FAILED_MESSAGE.NO_SNAPSHOTS
        case RENDER_JOB_FAILED_MESSAGE.NO_AVATAR.renderFarmMessage:
          return RENDER_JOB_FAILED_MESSAGE.NO_AVATAR
        default:
          return RENDER_JOB_FAILED_MESSAGE.DEFAULT
      }
    },

    /**
     * @returns {boolean}
     */
    isUploadingOrPending () {
      const { autorenderStatus, isUploading } = this.version

      return (
        isUploading ||
        autorenderStatus === JOB_STATUS.STARTED ||
        autorenderStatus === JOB_STATUS.PENDING
      )
    }
  },

  methods: {
    ...mapActions(['updateOption']),

    /**
     */
    emitAddOption () {
      this.$emit('add-option', { versionId: this.version.id })
    },

    /**
     * @param {object} payload
     */
    handleUpdateOption (payload) {
      this.$emit('update-option', payload)
    },

    /**
     * @param {object} payload
     */
    handleDeleteOption (payload) {
      this.$emit('delete-option', payload)
    },

    /**
     * @param {object} payload
     */
    handleAddImages (payload) {
      this.$emit('add-option-images', payload)
    },

    /**
     * @param {object} payload
     */
    handleUpdateImage (payload) {
      this.$emit('update-option-image', payload)
    },

    /**
     * @param {object} payload
     */
    handleDeleteImage (payload) {
      this.$emit('delete-option-image', payload)
    },

    /**
     * @param {Event} event
     */
    onSortDrop (event) {
      if (event.oldIndex !== event.newIndex) {
        const option = this.options[event.oldIndex]
        const payload = {
          styleId: this.activeVersion.styleId,
          versionId: option.versionId,
          optionId: option.id,
          index: event.newIndex
        }

        this.updateOption(payload)
        this.isSorting = false
      }
    },

    /**
     */
    disableDragDropUpload () {
      this.isSorting = true
    }
  }
}
</script>

<style lang="scss" scoped>
$border-radius-options: $border-radius-s * 2;
$render-status-icon-font-size: 38px;

.option-list {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

.option-list__add-option,
.option-list__options {
  padding: 0 spacing(2);
}

.option-list__add-option {
  width: fit-content;
}

.option-list__options {
  @include scroll-y-smooth;

  position: relative;
  height: 100%;
  max-height: 100%;

  .sortable-drag {
    background-color: $white;
  }
}

.option-list__option {
  position: relative;
  margin-bottom: spacing(1);
  padding: spacing(2);
  border: $border-divider;
  border-radius: $border-radius-options;
}

.option-item__dragged {
  height: spacing(12);
  visibility: hidden;
}

.option-item__option--rendering {
  display: flex;
  align-items: center;
}

.render-status {
  @include position-absolute;

  width: 100%;
  margin: spacing(1);
  padding: spacing(1);
  color: $grey-dark;
  text-align: center;
  word-break: break-word;

  > * {
    margin-bottom: spacing(1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $blue;
  }
}
</style>
